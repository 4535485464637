<template>
    <div class="col-md-12">
        <h2 class="fw-bolder"><a href="javascript:void(0)" class="text-black fas fa-angle-left" style="margin-right: 15px;" @click="goBack">
        </a>{{ title }}</h2>
    </div>
</template>
<script>
export default {
    props: {
        title: { type: String, default: '' },
    },
    methods:{
        goBack(){
            return this.$router.go(-1)
        },
    }
}
</script>