<template>
  <div class="row">
    <PageTitle :title="'Butuh Persetujuan'" />
  </div>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body p-9">
      <!-- <div class="row mb-3">
        <div style="flex: 0 0 auto; width: 100%">
          <h3 class="fw-bolder">Butuh Persetujuan</h3>
        </div>
      </div>
      <hr /> -->
      <Datatable v-if="refreshTable" ref="Datatable" :table-header="tabelHeader2" :dataurl="dataurl2"
        :filter="listFilter" :search="search" :routeurl="routeurl2" :deleteurl="deleteurl2" :methodeurl="methodeurl2">
        <template v-slot:cell-transaction_number="{ row: data }">
          {{ data.transaction_number }}
        </template>
        <template v-slot:cell-request_date="{ row: data }">
          {{ data.request_date.replace("T", " ") }}
        </template>
        <template v-slot:cell-last_change_date="{ row: data }">
          {{ data.last_change_date }}
        </template>
      </Datatable>
    </div>
  </div>
</template>
<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import PageTitle from '@/components/PageTitle.vue'
export default {
  components: {
    Datatable,
    PageTitle
  },
  data() {
    const fields2 = [
      { key: "counter", name: "no", sortable: true },
      { key: "transaction_number", name: "No Transaksi", sortable: true },
      { key: "request_date", name: "Tanggal Pengajuan", sortable: true },
      {
        key: "last_change_date",
        name: "Tanggal Terakhir Perubahan",
        sortable: true,
      },
    ];
    return {
      dataurl2: "/dashboard/v1/query-need-approval",
      methodeurl2: "post",
      routeurl2: "dashboard",
      deleteurl: "",
      deleteurl2: "",
      tabelHeader2: fields2,
      search: null,
      loading: false,
      dialogVisibleDelete: false,
      valDelete: null,
      listFilter: [{}],
      refreshTable: true,
    };
  },
  mounted() {
    setCurrentPageBreadcrumbs("Butuh Persetujuan", [
      {
        title: "Dashboard",
        link: "/dashboard",
      },
      {
        title: "Butuh Persetujuan",
        link: "#",
      },
    ]);
  },
  watch: {
    search(val) {
      if (val.length < 1) {
        this.listFilter[0].role_name = null;
        this.$refs.Datatable.fetchData;
      }
    },
  },
  methods: {
    cariData() {
      let val = this.search;
      if (val.length > 3) {
        this.listFilter[0].role_name = this.search;
        this.forceUpdate();
      }
    },
    add() {
      this.$router.push({ name: "master-akses-user-create" });
    },
    remoteMethod(val) {
      if (val) {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.options.filter((item) => {
            return item.label.toLowerCase().includes(val.toLowerCase());
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    btnDetail(val) {
      this.$router.push({
        name: this.routeurl + "-detail",
        params: { id: val },
      });
    },
    btnConfirmHapus(val) {
      this.dialogVisibleDelete = true;
      this.valDelete = val;

    },
    btnHapus(val) {
      this.dialogVisibleDelete = false;
      this.$apiservice.setHeader();
      this.$apiservice.delete(this.deleteurl + "?roleId=" + val).then((res) => {
        Swal.fire({
          title: "Data Terhapus",
          html: "Data berhasil dihapus",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Oke",
          customClass: {
            confirmButton: "btn fw-bold btn-success",
          },
        }).then(() => {
          this.$refs.Datatable.fetchData();
        });
      });
    },
    goBack() {
      return this.$router.go(-1);
    },
    forceUpdate() {
      this.refreshTable = false;
      this.$nextTick(() => {
        this.refreshTable = true;
      });
    },
  },
};
</script>
